import { gql } from '@apollo/client';

export const UPDATE_INTERCOM_CONTACT = gql`
	mutation updateIntercomContact($userObject: ContactInput!) {
		success: updateIntercomContact(userObject: $userObject)
	}
`;

export const TRACK_CONSENT_CHANGES = gql`
	mutation trackConsentChanges($consent: ConsentCategoriesInput!) {
		success: trackConsentChanges(consent: $consent)
	}
`;
